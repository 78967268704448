// import React from 'react'
// import CoreCard from './CoreCard'

// import img1 from '../../../resources/Core_Members_images/20.png'
// import img2 from '../../../resources/Core_Members_images/21.png'
// import img3 from '../../../resources/Core_Members_images/22.png'
// import img4 from '../../../resources/Core_Members_images/23.png'
// import img5 from '../../../resources/Core_Members_images/24.png'
// import img6 from '../../../resources/Core_Members_images/25.png'
// import img7 from '../../../resources/Core_Members_images/26.png'
// import img8 from '../../../resources/Core_Members_images/27.png'
// import img9 from '../../../resources/Core_Members_images/28.png'
// import img10 from '../../../resources/Core_Members_images/29.png'
// import img11 from '../../../resources/Core_Members_images/30.png'
// import img12 from '../../../resources/Core_Members_images/35.png'
// const ID_list = [
//     img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12
// ]


// export default function HODCards() {
//     return (
//         <>
//             <div className='cards-container'>
//                 {ID_list.map((ele, id) => {
//                     return (
//                         <CoreCard
//                             name="mentor 1"
//                             post="Mentor"
//                             imgUrl={ele}
//                             fb="wwww.google.com"
//                             insta="wwww.google.com"
//                             linkdin="wwww.google.com"
//                             key={id}
//                         />
//                     )
//                 })}
//             </div>
//         </>
//     )
// }

import React from 'react'
import CoreCard from './CoreCard'
import img1 from '../../../resources/Core_Members_images/17.png'
import img2 from '../../../resources/Core_Members_images/18.png'
import img3 from '../../../resources/Core_Members_images/31.png'
import img4 from '../../../resources/Core_Members_images/32.png'
import img5 from '../../../resources/Core_Members_images/19.png'
import img6 from '../../../resources/Core_Members_images/20.png'
import img7 from '../../../resources/Core_Members_images/21.png'
import img8 from '../../../resources/Core_Members_images/22.png'
import img9 from '../../../resources/Core_Members_images/23.png'
import img10 from '../../../resources/Core_Members_images/24.png'
import img11 from '../../../resources/Core_Members_images/25.png'
import img12 from '../../../resources/Core_Members_images/30.png'
import img13 from '../../../resources/Core_Members_images/28.png'
import img14 from '../../../resources/Core_Members_images/29.png'
import img15 from '../../../resources/Core_Members_images/27.png'
import img16 from '../../../resources/Core_Members_images/26.png'
import isha from '../../../resources/Core_Members_images/isha.png'
import vanshika from '../../../resources/Core_Members_images/vanshika.png'
const ID_list1 = [
    img1, img2, img3, img4
]
const ID_list2 = [
    img5, img6, img7, img8
]
const ID_list3 = [
    img9, img10, img11, img12
]
const ID_list4 = [
    img13, img14, img15, img16
]
const ID_list5 = [
    isha,vanshika
]
export default function HODCards() {
    return (
        <>
            <div className='cards-container'>
                {ID_list1.map((ele, id) => {
                    return (
                        <CoreCard
                            name="mentor 1"
                            post="Mentor"
                            imgUrl={ele}
                            fb="wwww.google.com"
                            insta="wwww.google.com"
                            linkdin="wwww.google.com"
                            key={id}
                        />
                    )
                })}
            </div>
            <div className='cards-container'>
                {ID_list2.map((ele, id) => {
                    return (
                        <CoreCard
                            name="mentor 1"
                            post="Mentor"
                            imgUrl={ele}
                            fb="wwww.google.com"
                            insta="wwww.google.com"
                            linkdin="wwww.google.com"
                            key={id}
                        />
                    )
                })}
            </div>
            <div className='cards-container'>
                {ID_list3.map((ele, id) => {
                    return (
                        <CoreCard
                            name="mentor 1"
                            post="Mentor"
                            imgUrl={ele}
                            fb="wwww.google.com"
                            insta="wwww.google.com"
                            linkdin="wwww.google.com"
                            key={id}
                        />
                    )
                })}
            </div>
            <div className='cards-container'>
                {ID_list4.map((ele, id) => {
                    return (
                        <CoreCard
                            name="mentor 1"
                            post="Mentor"
                            imgUrl={ele}
                            fb="wwww.google.com"
                            insta="wwww.google.com"
                            linkdin="wwww.google.com"
                            key={id}
                        />
                    )
                })}
            </div>
            <div className='cards-container'>
                {ID_list5.map((ele, id) => {
                    return (
                        <CoreCard
                            name="mentor 1"
                            post="Mentor"
                            imgUrl={ele}
                            fb="wwww.google.com"
                            insta="wwww.google.com"
                            linkdin="wwww.google.com"
                            key={id}
                        />
                    )
                })}
            </div>
        </>
    )
}
