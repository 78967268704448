export default function LeaderboardCard({
  image,
  teamName,
  department,
  rank,
  points,
  color,
}) {
  return (
    <div id="leaderboard-card" style={{ backgroundColor: color }}>
      <div>
        <div className="leaderboard-container">
          <img src={image} alt="" />
          <div className="info">
            <div className="team-info">
              <h3>{teamName}</h3>
              <p>{department}</p>
            </div>
            <div className="rank">
              <h2>{rank}</h2>
            </div>

            <div className="points">
              <h4>
                {points > 0 ? points : "0"} Point{points > 1 ? "s" : ""}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
