import LeaderboardCard from "./Leaderboards-components/LeaderboardCard";
import Artemis from "../images/teams/Artemis logo.jpg";
import Apollo from "../images/teams/Apollo logo.jpg";
import Ares from "../images/teams/Ares logo.jpg";
import Athena from "../images/teams/Athena logo.jpg";
import Demeter from "../images/teams/Demeter logo.jpg";
import Poseidon from "../images/teams/Poseidon logo.jpg";
import "../CSS/Leaderboard.css";
import { motion} from 'framer-motion'

export default function Leaderboards() {
  return (
    <>
      <div id="leaderboards">
        <h1>IDPT - LEADERBOARDS</h1>
        

        <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 0.8, delay: 0.1}}
      ><LeaderboardCard
          image={Ares}
          department="IT"
          rank="I"
          teamName="Ares"
          points={477}
          color="rgba(255, 80, 80, 1)"
        /></motion.div>
        
        <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 0.8, delay: 0.3}}
      ><LeaderboardCard
          image={Artemis}
          department="Computer"
          rank="II"
          teamName="Artemis"
          points={384}
          color="#fff"
        /></motion.div>

<motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 0.8, delay: 0.5}}
      ><LeaderboardCard
          image={Demeter}
          department="AICB"
          rank="III"
          teamName="Demeter"
          points={280}
          color="#61C9A8"
        /></motion.div>


<motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 0.8, delay: 0.7}}
      ><LeaderboardCard
          image={Apollo}
          department="CSDS AIDS"
          rank="V"
          teamName="Apollo"
          points={225}
          color="rgb(251, 212, 80)"
        /></motion.div>
   
   <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 0.8, delay: 0.9}}
      ><LeaderboardCard
          image={Poseidon}
          department="Mechanical"
          rank="IV"
          teamName="Poseidon"
          points={204}
          color="rgb(80, 164, 251)"
        /></motion.div>
        
     
        <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 0.8, delay: 1.1}}
      ><LeaderboardCard
          image={Athena}
          department="EXTC"
          rank="VI"
          teamName="Athena"
          points={41}
          color="rgb(248, 133, 69)"
        /></motion.div>
        
        
        
      </div>
    </>
  );
}