import React from "react";
import CCPcards from "./CCPcards";
import CPcards from "./CPcards";
import HODCards from "./HODcards";
// import JtSecretaryCards from './JtSecretaryCards'
// import MentorsCards from './MentorsCards'
import SecretaryCards from "./SecretaryCards";
import VCPcards from "./VCPcards";
import { motion } from "framer-motion";

export default function CoreMembers() {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 0.8, delay: 0.1}}
      >
        <CPcards />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
		transition={{ type: "tween", duration: 2, delay: 0.3}}
      >
        <CCPcards />
		</motion.div>
		<motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
		transition={{ type: "tween", duration: 2, delay: 2}}
      >
        <h1 style={{ color: "white", fontWeight: "bolder" }}>VCP's</h1>
        <VCPcards />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
		transition={{ type: "tween", duration: 6, delay: 1.5}}
      >
        <h1 style={{ color: "white", fontWeight: "bolder" }}>SECRETARY's</h1>
        <SecretaryCards />
      </motion.div>
      {/* <JtSecretaryCards />	 */}

      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "tween", duration: 6, delay: 2}}
      >
        <h1 style={{ color: "white", fontWeight: "bolder" }}>HOD's</h1>
        <HODCards />
        {/* <MentorsCards /> */}
      </motion.div>
    </>
  );
}